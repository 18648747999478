<!--  -->
<template>
  <el-dialog
  title="站内信详情"
  :visible.sync="dialogVisible"
  width="50%"
  :before-close="handleClose">
  <div>
    <el-descriptions title="">
      <el-descriptions-item label="发送时间">{{info.createTime}}</el-descriptions-item>
      <el-descriptions-item label="发送操作">{{info.title}}</el-descriptions-item>
      <el-descriptions-item label="接收用户名">{{info.accountName}}</el-descriptions-item>
      <el-descriptions-item label="接受用户ID">
        {{info.accountId}}
      </el-descriptions-item>
      <el-descriptions-item label="相关ID">{{info.param}}</el-descriptions-item>
    </el-descriptions>
    <div style="display:flex;">
      <span style="white-space:nowrap;margin-right:10px;">站内信内容:</span>
      <p style="padding-right:20px;">{{info.content}}</p>
    </div>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">关 闭</el-button>
  </span>
</el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
    };
  },
  props:['info'],
  components: {},
  computed: {},
  methods: {
    handleClose(done){
      this.dialogVisible = false;
      done();
    }
  }
}

</script>
<style lang='scss' scoped>
</style>