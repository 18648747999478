<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="refresh">
    </table-search>
    <div class="table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        @filter-change="filterChange"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="站内信ID">
        </el-table-column>
        <el-table-column
          width="300"
          prop="content"
          label="站内信内容">
          <template slot-scope="scope">
            <p class="content">{{scope.row.content}}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="通知操作">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.blue}">{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="param"
          label="触发相关ID">
          <template slot-scope="scope">
            <span>{{contentOptions[scope.row.type]}}ID：{{scope.row.param}}</span>
          </template>
        </el-table-column>  
        <el-table-column
          prop="accountId"
          label="接受用户ID">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="发送信息">
        </el-table-column>
        <el-table-column
          prop="readFlag"
          :filters="payFilter"
          column-key="readFlag"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.green}" v-show="scope.row.readFlag">已读</span>
            <span :style="{'color':COLOR.red}" v-show="!scope.row.readFlag">未读</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="viewHandle(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <mail-record-detail ref="mailRecordDetail" :info="info"></mail-record-detail>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import {accountList,getAppmessageDetail} from '../../api/service'
import MailRecordDetail from './coms/mail-record-detail.vue'
export default {
  data () {
    return {
      info:{},
      contentOptions:{
        '1':'招标求助'
      },
      loading:false,
      total:100,
      form:{
        page:1,
        perPage:10,
        readFlag:''
      },
      conditions:[
        {value:'content',label:'站内信内容'},
        {value:'accountId',label:'接受用户ID'}
      ],
      tableData:[
        {
          val:'1',
          id:1,
          status:'1'
        },
        {
          val:'2',
          id:2,
          status:'2'
        },
        {
          val:'3',
          id:3,
          status:'3'
        },
        {
          val:'4',
          id:4,
          status:'4'
        }
      ],
      multipleSelection:[],
        statusFilter:[
          {text: '正常', value: '1'}, 
          {text: '禁言', value: '2'}, 
          {text: '禁止登陆', value: '3'}
        ],
        applyFilter:[
          {text: '诉讼业务', value: '1'}, 
          {text: '婚姻家庭', value: '2'}, 
          {text: '合同纠纷', value: '3'},
          {text: '非诉讼业务', value: '4'},
          {text: '法律顾问', value: '5'},
          {text: '劳动纠纷', value: '6'},
          {text: '金融证券', value: '7'},
          {text: '公司经营', value: '8'},
          {text: '法律文书', value: '9'},
        ],
        typeFilter:[
            {text: '等待选标中', value: '1'},
            {text: '已中标', value: '2'}, 
            {text: '未中标', value: '3'}
        ],
        subjectFilter:[
            {text: '招标审核成功', value: '1'},
            {text: '招标审核失败', value: '2'}, 
            {text: '开标通知', value: '3'}, 
            {text: '邀请招标通知', value: '4'}, 
            {text: '投标中标通知', value: '5'}, 
            {text: '投标未中标通知', value: '6'}, 
            {text: '招标被终止', value: '7'}, 
            {text: '被邀请投标方', value: '8'}, 
            {text: '招标结标公示', value: '9'}, 
            {text: '商机推送', value: '9'}
        ],
        payFilter:[
            {text: '已读', value: 1},
            {text: '未读', value: 0}, 
        ]

    };
  },
  components: {
    TableSearch,
    MailRecordDetail
  },
  computed: {},
  methods: {
    viewHandle(row){
      this.getDetail(row.id);
      this.$refs.mailRecordDetail.dialogVisible = true;
    },
    getDetail(id){
      getAppmessageDetail({messageId:id}).then(res=>{
        let data = res.data;
        if(data.code ==='000'){
          this.info = data.content;
        }else{
          this.$message.warning(data.desc);
          console.log(data.desc)
        }
      })
    },
    refresh(){
      this.getList();
    },
    searchRes(para){
      console.log(para)
      this.form = Object.assign({page:1,perPage:this.form.perPage,readFlag:this.form.readFlag},para);
      this.getList();
    },
    getList(){
      this.loading = true;
      accountList(this.form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.tableData = data.content.list;
          this.loading = false;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){},
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val)
    },
    checkHandle(row){},
    userHandle(row){},
    editHandle(row){},
    forbidAll(){},
    forbid(){},
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    filterChange(filters){
      for(let key in filters){
        this.form[key] = filters[key].join();
      }
      this.form.page = 1;
      this.getList();
    },
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
.content{
  display: -webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
  overflow: hidden;
}
</style>