import instance from './axios.js'

// 站内信列表
export const accountList = (para)=>{
  return instance.get('/appMessage/list',{params:para})
}
// 站内信详情
export const getAppmessageDetail = (para)=>{
  return instance.get('/appMessage/detail',{params:para})
}
// 客服工单列表
export const problemList = (para)=>{
  return instance.get('/problemList/list',{params:para})
}
// 客服工单详情
export const problemDetail = (para)=>{
  return instance.get('/problemList/detail',{params:para})
}
// 回复
export const problemAnswer = (para)=>{
  return instance.post('/problemList/answer',para)
}
// 标记
export const markHandle = (para)=>{
  return instance.post('/problemList/mark',para)
}
// 取消标记
export const unmarkHandle = (para)=>{
  return instance.post('/problemList/unMark',para)
}
// 用户聊天记录
export const connectList = (para)=>{
  return instance.get('/connect/list',{params:para})
}
// 短信发送记录
export const getSmsRecordList = (para)=>{
  return instance.get('/sms/getSmsRecordList',{params:para})
}
